<template>
	<div class="footer">
		<div class="row__spacebetween">
			<a class="logo--svg" href="https://www.step1-ausbildungsmesse.de/">
				<StepOneSvg />
			</a>
			<div class="row_end ml-auto">
				<a
					href="https://step1ausbildungsmesse.de/kontakt/"
					class="footer__link"
					><p class="text__grey">Kontakt</p></a
				>
				<a
					href="https://step1ausbildungsmesse.de/datenschutz/"
					class="footer__link"
					><p class="text__grey">Datenschutz</p></a
				>
				<a
					href="https://step1ausbildungsmesse.de/impressum/"
					class="footer__link"
					><p class="text__grey">Impressum</p></a
				>
			</div>
		</div>
		<div class="devider"></div>
		<div class="row__spacebetween">
			<p class="text__grey mb-30">info@step1ausbildungsmesse.de</p>
			<p class="text__lightgrey mb-30">© STEP1 Höxter GbR 2020</p>
		</div>
	</div>
</template>

<script>
	import { mapActions } from "vuex";
	export default {
		name: "Footer",
		data() {
			return {};
		},
		methods: {
			...mapActions([]),
		},
		created() {},
		computed: {},
		mounted() {},
	};
</script>
<style lang="scss" scoped></style>
