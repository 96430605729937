<template>
	<div class="row__start row__start--stretch company__row mt-15">
		<div class="row__start--flex1">
			<div v-if="oneCompany" class="h100 w-100 mb-60">
				<div class="general__box" v-if="oneCompany.aboutUs">
					<h3 class="text__bold">Über uns</h3>
					<p class="mt-15">{{ oneCompany.aboutUs }}</p>
				</div>
				<div
					class="general__box"
					v-if="oneCompany.apprenticeships.length > 0"
				>
					<h3 class="text__bold mb-15">Dein Einstieg bei uns</h3>
					<p
						v-for="(apprenticeship,
						index) in oneCompany.apprenticeships"
						:key="index"
					>
						-
						{{ apprenticeship.name }}
						<a
							v-if="apprenticeship.website"
							class="p__link"
							:href="apprenticeship.website"
							target="_blank"
						>
							<LinkSvg />
						</a>
					</p>
				</div>
				<div class="general__box" v-if="oneCompany.offers.length > 0">
					<h3 class="text__bold mb-15">Unsere Angebote</h3>
					<p v-for="(offer, index) in oneCompany.offers" :key="index">
						-
						{{ offer.name }}
					</p>
				</div>
			</div>
		</div>
		<div class="row__start--flex3 row__flex3--w43 mb-60">
			<div v-if="oneCompany" class="h100 ml-30">
				<div class="general__box" v-if="oneCompany.howToApply">
					<h3 class="text__bold">So kannst Du Dich bewerben</h3>
					<p class="mt-15">{{ oneCompany.howToApply }}</p>
				</div>
				<div class="general__box" v-if="oneCompany.facts">
					<h3 class="text__bold">Schon gewusst?</h3>
					<p class="mt-15">{{ oneCompany.facts }}</p>
				</div>

				<div
					class="general__box"
					v-if="oneCompany.info || oneCompany.mainContact"
				>
					<h3 class="text__bold" v-if="oneCompany.mainContact">
						Hauptansprechpartner*in des Unternehmens
					</h3>
					<div
						class="mt-15 mb-40 row__start row__responsive--wrap"
						v-if="oneCompany.mainContact"
					>
						<div
							class="contact__box mt-15"
							v-if="oneCompany.mainContact.image"
						>
							<img :src="oneCompany.mainContact.image" alt="" />
						</div>
						<div
							class="column__start mt-10 "
							:class="
								oneCompany.mainContact.image
									? 'ml-25 responsive-no-ml'
									: ''
							"
						>
							<h2 class="text__bold text__black m-0">
								{{
									oneCompany.mainContact.name
										? oneCompany.mainContact.name
										: "Kontakt"
								}}
							</h2>
							<p v-if="oneCompany.mainContact.jobTitle">
								{{ oneCompany.mainContact.jobTitle }}
							</p>
							<a
								class="row__start mt-15"
								v-if="oneCompany.mainContact.mail"
								:href="'mailto:' + oneCompany.mainContact.mail"
							>
								<div class="w-40">
									<MailSvg />
								</div>
								<p>{{ oneCompany.mainContact.mail }}</p>
							</a>
							<a
								class="row__start  "
								:class="
									!oneCompany.mainContact.mail
										? 'mt-15'
										: 'mt-4p'
								"
								v-if="oneCompany.mainContact.phone"
								:href="'tel:' + oneCompany.mainContact.phone"
							>
								<div class="w-40">
									<PhoneSvg />
								</div>
								<p>{{ oneCompany.mainContact.phone }}</p>
							</a>
						</div>
					</div>

					<h3 class="text__bold" v-if="oneCompany.info">
						Allgemeine Kontaktdaten
					</h3>
					<div
						class="mt-15 row__start row__responsive--wrap"
						v-if="oneCompany.info"
					>
						<div
							class="mr-25"
							v-if="
								oneCompany.info.name ||
									oneCompany.info.street ||
									oneCompany.info.postalcode ||
									oneCompany.info.place
							"
						>
							<p v-if="oneCompany.info.name" class="mt-4p">
								{{ oneCompany.info.name }}
							</p>
							<p v-if="oneCompany.info.street" class="mt-4p">
								{{ oneCompany.info.street }}
							</p>
							<p
								v-if="
									oneCompany.info.postalcode ||
										oneCompany.info.place
								"
								class="mt-4p"
							>
								{{
									!oneCompany.info.place
										? oneCompany.info.postalcode + " "
										: oneCompany.info.postalcode +
										  " " +
										  oneCompany.info.place
								}}
							</p>
						</div>
						<div>
							<a
								:href="'mailto:' + oneCompany.info.mail"
								class="row__start mt-4p"
								v-if="oneCompany.info.mail"
							>
								<div class="w-40">
									<MailSvg />
								</div>
								<p>{{ oneCompany.info.mail }}</p>
							</a>
							<a
								class="row__start mt-4p"
								v-if="oneCompany.info.phone"
								:href="'tel:' + oneCompany.info.phone"
							>
								<div class="w-40">
									<PhoneSvg />
								</div>
								<p>{{ oneCompany.info.phone }}</p>
							</a>
							<a
								class="row__start mt-4p"
								v-if="oneCompany.info.website"
								:href="
									checkCompanyWebsite(oneCompany.info.website)
								"
								target="_blank"
							>
								<div class="w-40">
									<EarthSvg />
								</div>
								<p>{{ oneCompany.info.website }}</p>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapActions, mapGetters } from "vuex";
	export default {
		name: "CompanyContent",
		props: ["id"],
		data() {
			return {};
		},
		methods: {
			...mapActions(["getOneCompany"]),
			checkCompanyWebsite(link) {
				//adds https if link doesnt start with it
				var prefix = "http";
				if (link.substr(0, prefix.length) !== prefix) {
					link = "https://" + link;
				}
				let url = new URL(link);
				return url;
			},
		},
		created() {
			this.getOneCompany({ id: this.id });
		},
		computed: {
			...mapGetters(["oneCompany"]),
		},
		mounted() {},
	};
</script>
